import {useEffect, useState} from 'react';
import { VenueDetails } from '../components/pages/venues/venueModels';
import { EventSeriesProps } from '../components/pages/calendar/types/eventModel';
import { UserDetails } from '../models/user/userInfo';
import moment from 'moment';

export function fetchVenues(authToken:string|undefined):Promise<VenueDetails[]>{
  return fetch(
    process.env.REACT_APP_REFLEBULA + '/a1/tga/venues',
    {
      headers: authToken ? 
        { 'Authorization': `Bearer ${authToken}` } : 
        { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
  ).then((response) => {
      if (!response.ok) {
          throw response
      } else {
          return response.json().then(r=>r.data)
      }
  })
}


export function fetchEventAttendees(eventId:number):Promise<string[]|undefined>{
  /* Retuns an array of usernames currently RSVP'd for a given event*/
  
  const requestOptions = {
    method: 'GET',
    headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
  }
  return fetch(
    `${process.env.REACT_APP_REFLEBULA}/a1/tga/events/${eventId}/rsvps`,requestOptions
  ).then((response) => {
      if (!response.ok) {
          throw response
      } else {
          return response.json().then(res=>res.data.attendees)
      }
  })
}


export function fetchSeriesById(seriesId:number, authToken:string|undefined):Promise<EventSeriesProps|undefined>{
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
  }
  return fetch(
    process.env.REACT_APP_REFLEBULA + '/a1/tga/event_series/' + seriesId.toString(),requestOptions
  ).then((response) => {
      if (!response.ok) {
          throw response
      } else {
          return response.json().then(res=>res.data)
      }
  }).then((res) => {
    if (!res) 
    {
      return;
    }
    //TODO: Change to GMT->EST
    const tz = 'America/New_York';
    const startTime = moment.tz(res.eventStartTime,'HH:mm:ss',`GMT`);
    const endTime = moment.tz(res.eventEndTime,'HH:mm:ss',`GMT`);
    const result = { ...res, eventStartTime: res.eventStartTime ? startTime.clone().tz(tz).format('HH:mm:ss'): '00:00:00', eventEndTime: res.eventEndTime ? endTime.clone().tz(tz).format('HH:mm:ss'): '00:00:00'}
    return result;
  })
}

export function fetchUserById(userId:number, authToken:string|undefined):Promise<UserDetails>{
  return fetch(
    process.env.REACT_APP_REFLEBULA + '/a1/tga/user/' + userId.toString(),
    {
      headers: authToken ? 
        { 'Authorization': `Bearer ${authToken}` } : 
        { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
  ).then((response) => {
      if (!response.ok) {
          throw response
      } else {
          return response.json()
      }
  }).then((res) => {
    if (!res) 
    {
      return;
    }
    //TODO: Change to GMT->EST
    const tz = 'America/New_York';
    const startTime = moment.tz(res.startTime,`GMT`);
    const endTime = moment.tz(res.endTime,`GMT`);
    const result = { ...res, startTime: res.startTime ? startTime.clone().tz(tz).toDate(): new Date(), endTime: res.endTime ? endTime.clone().tz(tz).toDate(): new Date() }
    return result;
  })
}


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    windowWidth:width,
    windowHeight:height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      windowWidth:width,
      windowHeight:height
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function validatePermission(permissionName:string, authToken:string):Promise<Boolean>{
  const requestOptions = {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
    body: JSON.stringify({ permissionName })
  }

  return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/user/permission', requestOptions)
    .then((res) => (res.json()))
    .then((res) => {
      if (res.result && res.result === 'success') {
        return res.value;
      }
    })
  }
